import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { MouseEvent, useMemo, useRef, useState } from 'react'

import {
  AcButton,
  AcCounterInput,
  AcTemplateProductTitle,
  AcTypography,
} from '@components'
import { DRAG_TYPES, ICONS, LABELS } from '@constants'
import { AcIntlPrice } from '@helpers/ac-intl-number.helper'
import { useOnClickOutside } from '@hooks/use-click-outside'
import {
  ITemplateCategory,
  ITemplateDaysName,
  ITemplateProduct,
} from '@typings'
import { AcProductSelector } from '../ac-product-selector/ac-product-selector'

import { useStore } from '@hooks/use-store'
import styles from './ac-template-product-item.module.scss'
import { useDrag } from 'react-dnd'

export interface IAcTemplateProductItem extends ITemplateProduct {
  readOnly?: boolean
  /** @default true */
  is_shuffable?: boolean
  is_deletable?: boolean
  withActions?: boolean
  category_id?: ITemplateCategory['id']
  day: ITemplateDaysName
  product_type?: keyof typeof DRAG_TYPES
}
export const AcTemplateProductItem = observer(
  ({
    id,
    name,
    quantity,
    sale,
    state,
    is_locked,
    is_shuffable = true,
    is_deletable = true,
    readOnly,
    day,
    withActions = true,
    category_id,
    product_id,
    product_type = DRAG_TYPES.PRODUCT,
  }: IAcTemplateProductItem) => {
    const [{ padding }, productDragRef] = useDrag(
      () => ({
        type: product_type,
        item: { id, name, from: day },
        collect: monitor => ({
          padding: monitor.isDragging() ? '0 5rem' : '0',
        }),
        canDrag: !readOnly && !is_locked,
      }),
      [is_locked, readOnly]
    )
    const [isExpanded, setIsExpanded] = useState(false)
    const quantityDirty = useRef(false)

    const productItemRef = useRef<HTMLDivElement>(null)
    const { template } = useStore()

    useOnClickOutside(productItemRef, () => {
      if (!quantityDirty.current) setIsExpanded(false)
    })

    const getProductItemClasses = useMemo(
      () =>
        clsx(
          styles['ac-template-product-item'],
          isExpanded &&
            !readOnly &&
            styles['ac-template-product-item--expanded'],
          is_locked && styles['ac-template-product-item--locked'],
          readOnly && styles['ac-template-product-item--read-only']
        ),
      [isExpanded, state, is_locked, readOnly]
    )

    const onProductItemClick = (event: MouseEvent) => {
      if (!readOnly && withActions && event.detail > 1) {
        event.preventDefault()
        setIsExpanded(true)
      }
    }

    const handleDeleteItem = (id: number) => {
      template.handleDeleteProduct(id)
    }

    const handleQuantityChange = (id: number, newQuantity: number) => {
      quantityDirty.current = false

      if (newQuantity !== quantity) {
        if (product_type === 'FRUIT') {
          return template.handleFruitQuantityChange(
            day,
            product_id,
            newQuantity
          )
        }

        template.handleProductQuantityChange(id, newQuantity)
      }
    }

    const handleLockItem = (id: ITemplateProduct['id']) => {
      template.handleLockProduct(id, !is_locked)
    }

    const handleBibi = (template_product_id: ITemplateProduct['id']) => {
      template.handleBibiShuffle({
        template_product_id,
      })
    }

    return (
      <span
        ref={productDragRef}
        style={{ padding }}>
        <div
          ref={productItemRef}
          className={getProductItemClasses}
          onMouseDown={onProductItemClick}>
          <div className={styles['ac-template-product-item-content']}>
            <AcTemplateProductTitle
              state={state}
              name={name}
            />
            <div className={styles['ac-template-product-item-numbers']}>
              {(is_deletable || is_shuffable) && (
                <div className={styles['ac-template-product-item-quickmenu']}>
                  {is_shuffable && !is_locked && (
                    <AcButton
                      className={
                        styles['ac-template-product-item-quickmenu-button']
                      }
                      padding="none"
                      variant="icon"
                      color="secondary"
                      label={LABELS.BIBI_SHUFFLE}
                      icon={ICONS.BIBI}
                      onClick={() => handleBibi(id)}
                    />
                  )}
                  {is_deletable && (
                    <AcButton
                      className={
                        styles['ac-template-product-item-quickmenu-button']
                      }
                      padding="none"
                      variant="icon"
                      color="secondary"
                      onClick={() => handleDeleteItem(id)}
                      label={LABELS.REMOVE}
                      icon={ICONS.DELETE}
                    />
                  )}
                </div>
              )}
              <AcCounterInput
                name={`${id}-quantity`}
                id={`${id}-quantity`}
                value={quantity}
                onBlur={event =>
                  handleQuantityChange(id, parseInt(event.target.value))
                }
                onMouseDown={event => event.stopPropagation()}
                onChange={() => (quantityDirty.current = true)}
                readOnly={readOnly}
              />
              <AcTypography size="xs">x{AcIntlPrice(sale)}</AcTypography>
            </div>
          </div>
          <div className={styles['ac-template-product-item-actions']}>
            {isExpanded && !readOnly && (
              <>
                <AcButton
                  label={is_locked ? LABELS.UNLOCK : LABELS.LOCK}
                  fullWidth
                  padding="md"
                  icon={ICONS.LOCK}
                  onClick={() => handleLockItem(id)}
                />
                <AcProductSelector
                  label={LABELS.REPLACE_PRODUCT}
                  loading={template.product_selector.loading}
                  productsGroupedByCategory={template.product_selector.products}
                  categories={template.product_selector.categories}
                  category_id={category_id}
                  onSearch={params => template.handleProductSearch(day, params)}
                  onChange={([product]) =>
                    template.handleReplaceProduct(id, product)
                  }
                />
              </>
            )}
          </div>
        </div>
      </span>
    )
  }
)
