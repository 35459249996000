import Api, { IApi } from '@api'
import { saveToStorage } from '@helpers/browser-storage.helpers'
import { action, makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import Websocket, { IWebsocket } from '../websocket/index.websocket'
import { AuthStore } from './auth.stores'
import { GlobalSearchStore } from './global-search.stores'
import { InvoicesStore } from './invoices.stores'
import { ProductStore } from './products.stores'
import { SuppliersStore } from './suppliers.stores'
import { TemplateStore } from './template.stores'
import UiStore from './ui.store'
import { EmployeeStore } from './employees.stores'
import { TagsStore } from './tags.stores'
import { CustomerStore } from './customer.stores'
export class Store {
  api: IApi
  socket: IWebsocket
  auth: AuthStore
  ui: UiStore
  customer: CustomerStore
  employees: EmployeeStore
  products: ProductStore
  suppliers: SuppliersStore
  invoices: InvoicesStore
  global_search: GlobalSearchStore
  tags: TagsStore
  template: TemplateStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.socket = Websocket()
    this.auth = new AuthStore(this)
    this.ui = new UiStore(this)
    this.customer = new CustomerStore(this)
    this.employees = new EmployeeStore(this)
    this.products = new ProductStore(this)
    this.suppliers = new SuppliersStore(this)
    this.invoices = new InvoicesStore(this)
    this.tags = new TagsStore(this)
    this.template = new TemplateStore(this)
    this.global_search = new GlobalSearchStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }
}

export const StoreContext = createContext<Store>(new Store())
