import { KEYS } from '@constants'
import { AcAssert } from '@helpers/ac-assert.helpers'
import {
  ChangeEvent,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'
import { AcFormContext } from '../ac-form-provider/ac-form-provider'
import { AcFormErrorContext } from '../ac-form/ac-form'
import { AcSelect } from '../ac-select/ac-select'
import {
  IAcTextInputProps,
  IAcTextareaInputProps,
} from '../ac-text-input/ac-text-input'
import {
  AcImageInput,
  AcRadioInput,
  AcSelectChips,
  AcTextInput,
} from '../index.core.component'
import styles from './ac-form-input.module.scss'

type OmitId<T> = Omit<T, 'id'>

export type IAcFormInput<T extends FieldValues, I> = {
  id?: string
  label: string
  name: keyof FieldValues
  validate?: string
  loading?: boolean
  hideAsterisk?: boolean
  onError?: (arg: FieldError) => void
  transform?: {
    input?: (value: IAcTextInputProps['value']) => string
    output?: (event: ChangeEvent) => string
  }
  /** @default true */
  withLabel?: boolean
} & UseControllerProps<T> &
  OmitId<I>

type IAcImageCropInputProps = Parameters<typeof AcImageInput>[0] & {
  type: 'image-crop'
  readOnly?: boolean
}

type IAcSelectInputProps = Omit<
  Parameters<typeof AcSelect>[0],
  'outputFormat' | 'onChange'
> & {
  type: 'select'
  readOnly?: boolean
  snakeField?: string
  outputFormat?: 'object' | 'value' | 'object-snake'
}

type IAcMultiSelectInputProps = Omit<
  Parameters<typeof AcSelect>[0],
  'inputFormat' | 'outputFormat' | 'onChange'
> & {
  type: 'multi-select'
  readOnly?: boolean
}
type IAcSelectChipsInputProps = Parameters<typeof AcSelectChips>[0] & {
  type: 'select-chip'
  readOnly?: boolean
  outputFormat?: 'object' | 'value'
}
type IAcRadioInputProps = Parameters<typeof AcRadioInput>[0] & {
  type: 'radio'
  /** @default false */
  withLabel?: boolean
}

type AcSelectOutputType = 'object' | 'value' | undefined

function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcImageCropInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcSelectInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcMultiSelectInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcSelectChipsInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcRadioInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcTextareaInputProps>
): JSX.Element
function AcFormInput<T extends FieldValues>(
  props: IAcFormInput<T, IAcTextInputProps>
): JSX.Element

function AcFormInput<T extends FieldValues>({
  rules,
  name,
  id: customId,
  label: labelWithoutAsterix,
  readOnly: customReadOnly,
  loading: customLoading,
  onError: customOnError,
  validate,
  hideAsterisk,
  transform,
  ...props
}: IAcFormInput<
  T,
  | IAcImageCropInputProps
  | IAcTextareaInputProps
  | IAcTextInputProps
  | IAcSelectInputProps
  | IAcSelectChipsInputProps
  | IAcRadioInputProps
  | IAcMultiSelectInputProps
>) {
  const [error, setError] = useState<FieldError | undefined>()
  const { control, setValue } = useFormContext<T>()
  const formContext = useContext(AcFormContext)
  const formErrorContext = useContext(AcFormErrorContext)
  const id = customId || name + useId()

  const readOnly = customReadOnly || formContext?.readOnly
  const loading = customLoading || formContext?.loading
  const onError = customOnError || formErrorContext?.onError
  const register = formContext?.registerInput

  const PropertyAssert = new AcAssert('AcFormInput')
  PropertyAssert.isNullish({ FORM_INPUT_MISSING_FORM_PROVIDER: control })
  if (PropertyAssert.error) return null

  const label = useMemo(() => {
    const showAsterix = !hideAsterisk && /required/gm.test(validate || '')

    if (showAsterix) return labelWithoutAsterix + '*'
    else return labelWithoutAsterix
  }, [labelWithoutAsterix, validate, hideAsterisk])

  const showLabel = useMemo(() => {
    if (props.type === 'radio' && !(props as IAcRadioInputProps).withLabel)
      return false
    if (props.withLabel === false) return false
    return true
  }, [props.type, props.withLabel])

  useEffect(() => {
    register({ name, validate, label: labelWithoutAsterix })
  }, [validate])

  useEffect(() => {
    error && onError?.(error)
  }, [error])

  /**
   * @description This `updateSnakeField` function is made for `AcSelect`.
   * It will keep objects in sync with snake fields
   * For example: `product: { id: 1, value: 'Banana' }`
   * will sync to `product_id`
   *
   */
  const updateSnakeField = (e: any) => {
    if (!setValue || !props) return

    const { valueKey, snakeField } = props as IAcSelectInputProps
    const field: any = snakeField ? snakeField : `${name}_${valueKey}`
    setValue(field, e[valueKey])
  }

  return (
    <div className={styles['ac-form-input']}>
      {showLabel && <label htmlFor={id}>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={context => {
          setError(context.fieldState.error)
          switch (props.type) {
            case 'image-crop':
              return (
                <AcImageInput
                  id={id}
                  readOnly={readOnly}
                  loading={loading}
                  // error={context.fieldState.error?.message}
                  {...(props as OmitId<IAcImageCropInputProps>)}
                  {...context.field}
                />
              )

            case 'select': {
              const _props = props as Omit<IAcSelectInputProps, 'id'>
              let activateSnakeField = false
              let selectOutput: AcSelectOutputType = KEYS.OBJECT

              if (_props.outputFormat === 'object-snake') {
                activateSnakeField = true
                selectOutput = KEYS.OBJECT
              } else if (
                _props.outputFormat === KEYS.OBJECT ||
                _props.outputFormat === KEYS.VALUE
              ) {
                selectOutput = _props.outputFormat
              }

              return (
                <AcSelect
                  id={id}
                  readOnly={readOnly}
                  loading={loading}
                  error={context.fieldState.error?.message}
                  defaultValue={context.field.value}
                  {...(_props as Omit<typeof _props, 'outputFormat'>)}
                  {...context.field}
                  outputFormat={selectOutput}
                  onChange={e => {
                    if (activateSnakeField) updateSnakeField(e)
                    context.field.onChange(e)
                  }}
                />
              )
            }

            case 'multi-select': {
              const _props = props as Omit<IAcMultiSelectInputProps, 'id'>

              return (
                <AcSelect
                  id={id}
                  selectType="multi"
                  readOnly={readOnly}
                  loading={loading}
                  error={context.fieldState.error?.message}
                  defaultValue={context.field.value}
                  {...(_props as Omit<typeof _props, 'outputFormat'>)}
                  {...context.field}
                  onChange={e => context.field.onChange(e)}
                />
              )
            }

            case 'radio': {
              return (
                <AcRadioInput
                  readOnly={readOnly}
                  // loading={loading}
                  // error={context.fieldState.error?.message}
                  {...(props as OmitId<IAcRadioInputProps>)}
                  {...context.field}
                  value={context.field.value}
                />
              )
            }

            case 'select-chip':
              return (
                <AcSelectChips
                  error={context.fieldState.error?.message}
                  readOnly={readOnly}
                  {...(props as IAcSelectChipsInputProps)}
                  value={context.field.value}
                  onChange={context.field.onChange}
                />
              )

            default:
              return (
                <AcTextInput
                  id={id}
                  readOnly={readOnly}
                  loading={loading}
                  error={context.fieldState.error?.message}
                  {...(props as OmitId<IAcTextInputProps>)}
                  {...context.field}
                  value={
                    transform?.input
                      ? transform.input(context.field.value)
                      : context.field.value
                  }
                  onChange={(e: ChangeEvent) =>
                    context.field.onChange(
                      transform?.output ? transform.output(e) : e
                    )
                  }
                />
              )
          }
        }}
      />
    </div>
  )
}

export { AcFormInput }
