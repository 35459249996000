import {
  AcForm,
  AcFormCard,
  AcFormInput,
  AcProfileTabTags,
  AcTabs,
} from '@components'
import { DAYS, KEYS, LABELS, TAG_CATEGORIES, WEEKDAYS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ICustomerProfile } from '@typings'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import styles from './customer-profile-form.module.scss'

export type ICustomerProfileForm = {
  onError: () => undefined | void
}

export const CustomerProfileForm = observer(
  ({ onError }: ICustomerProfileForm) => {
    const { tags, customer } = useStore()
    const FormInput = AcFormInput<ICustomerProfile>

    const getTagTabs = useMemo(() => {
      return [
        {
          label: TAG_CATEGORIES[0].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_diet_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'first',
        },
        {
          label: TAG_CATEGORIES[1].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_allergy_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'second',
        },
        {
          label: TAG_CATEGORIES[2].label,
          component: () => (
            <AcProfileTabTags
              data={tags.current_facility_tags}
              loading={tags.loading}
            />
          ),
          disabled: false,
          id: 'third',
        },
      ]
    }, [
      tags.loading,
      tags.current_allergy_tags,
      tags.current_diet_tags,
      tags.current_facility_tags,
      customer.profile_tags,
    ])

    const getWeekdays = useMemo(() => {
      const keys = Object.keys(WEEKDAYS)
      const daysArray: {
        id: number
        label: string
        key: string
        abbr: string
      }[] = []
      return keys.reduce(
        (bulk, curr, i) => [
          ...bulk,
          {
            id: i,
            key: curr,
            label: WEEKDAYS[curr],
            abbr: DAYS.NL_SHORT[curr] as string,
          },
        ],
        daysArray
      )
    }, [])

    return (
      <AcForm
        onError={onError}
        layout="column">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <AcFormCard title={LABELS.CUSTOMER_INFO}>
            <FormInput
              id={KEYS.LUNCHERS}
              name={KEYS.LUNCHERS}
              label={LABELS.NUMBER_OF_LPD}
              type="number"
              validate="required|numeric"
            />
            <FormInput
              id="delivery_days"
              name={'delivery_days'}
              type="select-chip"
              labelKey="abbr"
              options={getWeekdays}
              label={LABELS.DELIVERY_DAYS}
              valueKey="key"
              validate="required"
              outputFormat="value"
            />
            <FormInput
              id={'lunch_days'}
              name={'lunch_days'}
              type="select-chip"
              labelKey="abbr"
              outputFormat="value"
              valueKey="key"
              options={getWeekdays}
              label={LABELS.LUNCH_DAYS}
              validate="required"
            />
            <FormInput
              id={'eating_behaviour'}
              name={'eating_behaviour'}
              type="textarea"
              label={LABELS.EATING_BEHAVIOUR}
              validate="required"
            />
          </AcFormCard>
          <AcFormCard
            title={LABELS.DATES}
            className={styles['ac-form-card-modified']}>
            <FormInput
              id={KEYS.FIRST_CREATED_AT}
              name={KEYS.FIRST_CREATED_AT}
              label={LABELS.CREATED}
              readOnly
              transform={{
                input: value =>
                  value
                    ? dayjs(value as string).format('DD-MM-YYYY HH:mm')
                    : '',
              }}
            />
            <FormInput
              id={KEYS.UPDATED_AT}
              name={KEYS.UPDATED_AT}
              label={LABELS.LAST_UPDATED}
              readOnly
              transform={{
                input: value =>
                  value
                    ? dayjs(value as string).format('DD-MM-YYYY HH:mm')
                    : '',
              }}
            />
          </AcFormCard>
        </div>
        <AcFormCard title={LABELS.TAGS}>
          <AcTabs tabs={getTagTabs} />
        </AcFormCard>
      </AcForm>
    )
  }
)
