import { ICONS, LABELS } from '@constants'
import { useOnClickOutside } from '@hooks/use-click-outside'
import clsx from 'clsx'
import React, {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AcInputBase } from '../ac-input-base/ac-input-base'
import {
  AcButton,
  AcCard,
  AcCheckbox,
  AcIcon,
  AcTypography,
} from '../index.core.component'
import styles from './ac-select.module.scss'

type IAcSelectOption = Record<string, string | number | null>

interface IAcSelectProps {
  name: string
  className?: string
  selectType?: 'single' | 'multi'
  id: string
  options: any[]
  valueKey: string
  labelKey: string
  defaultValue?: any
  withSearch?: boolean
  loading?: boolean
  placeholder?: string
  disabled?: boolean
  hint?: string
  readOnly?: boolean
  withEmpty?: boolean
  error?: string | boolean
  /** @default 'object' */
  outputFormat?: 'object' | 'value'
  /** @default 'object' */
  inputFormat?: 'object' | 'value'
  onChange: (newValue: any) => void
  renderOption?: (option: IAcSelectOption) => ReactNode
}

const AcSelect = forwardRef<HTMLInputElement, IAcSelectProps>(
  (
    {
      name,
      id,
      options,
      defaultValue = null, //10
      withSearch = false,
      valueKey,
      selectType = 'single',
      labelKey,
      loading,
      disabled,
      hint,
      readOnly,
      error,
      placeholder,
      withEmpty = false,
      renderOption,
      className,
      outputFormat = 'object',
      inputFormat = 'object',
      onChange,
    },
    ref
  ): JSX.Element => {
    const [showOptions, setShowOptions] = useState(false)
    const [query, setQuery] = useState<string | null>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [selection, setSelection] = useState<IAcSelectOption[]>([])

    useOnClickOutside(dropdownRef, () => handleClickOutside())

    //@TO-DO: more elaborate click outside behaviour to explicitly give feedback to user.
    const handleClickOutside = () => {
      if (withSearch) setQuery(null)
      setShowOptions(false)
    }

    const isSelected = useCallback(
      (option: IAcSelectOption) =>
        selection.some(
          selectedItem => selectedItem[valueKey] === option[valueKey]
        ),
      [selection]
    )

    const handleMultiSelectOptionClick = (
      event: React.MouseEvent | React.KeyboardEvent,
      option: IAcSelectOption
    ) => {
      event.stopPropagation()
      let newValues = selection
      const elementExists = isSelected(option)

      if (elementExists) {
        newValues = newValues.filter(
          singleSelected => singleSelected[valueKey] !== option[valueKey]
        )
      } else {
        newValues = [...newValues, option]
      }
      setSelection(newValues)
    }

    const handleSingleSelectOptionClick = (
      event: React.MouseEvent | React.KeyboardEvent,
      option: IAcSelectOption
    ) => {
      setShowOptions(!showOptions)
      event.stopPropagation()
      setQuery(null)

      if (!onChange) return

      if (outputFormat === 'object') {
        onChange(option)
      }
      if (outputFormat === 'value') {
        onChange(option?.[valueKey])
      }
    }

    const handleOptionClick = (
      event: React.MouseEvent | React.KeyboardEvent,
      option: IAcSelectOption
    ) => {
      return selectType === 'single'
        ? handleSingleSelectOptionClick(event, option)
        : handleMultiSelectOptionClick(event, option)
    }

    const handleFocusKeyDown = (
      event: React.KeyboardEvent,
      option?: IAcSelectOption
    ) => {
      const optionFocussed = document.activeElement?.tagName === 'OPTION'
      let preventDefault = true

      switch (event.code) {
        case 'Space':
        case 'NumpadEnter':
        case 'Enter': {
          setShowOptions(true)
          if (optionFocussed && option) {
            handleOptionClick(event, option)
            const baseInput = dropdownRef.current?.firstChild as HTMLDivElement
            baseInput?.focus?.()
          }
          break
        }
        case 'Escape':
        case 'Tab': {
          setShowOptions(false)
          preventDefault = false
          break
        }
        case 'ArrowUp':
        case 'ArrowDown': {
          if (!dropdownRef.current) return

          const nextOptionSelector =
            event.code === 'ArrowDown'
              ? 'nextElementSibling'
              : 'previousElementSibling'

          if (document.activeElement && optionFocussed) {
            const nextOption = document.activeElement[
              nextOptionSelector
            ] as HTMLOptionElement
            nextOption?.focus()
          } else dropdownRef.current?.querySelector('option')?.focus()
          break
        }
        default: {
          preventDefault = false
        }
      }
      if (preventDefault) event.preventDefault()
    }

    const renderOptions = useCallback(() => {
      //@TO-DO: Better filtering in options
      const filterOptions = query
        ? options.filter(singleOption =>
            JSON.stringify(singleOption[labelKey])
              .toLowerCase()
              .includes(query.toLowerCase())
          )
        : options

      return filterOptions.map(singleOption =>
        renderOption ? (
          <span
            key={singleOption[valueKey]}
            className={styles['ac-select-options-option']}
            onClick={event => handleOptionClick(event, singleOption)}
            onKeyDown={event => handleFocusKeyDown(event, singleOption)}
            tabIndex={0}>
            {renderOption(singleOption)}
          </span>
        ) : (
          <>
            <span
              role="option"
              className={clsx(
                styles['ac-select-options-option'],
                styles['ac-select-options-option--default'],
                selectType === 'multi' &&
                  styles['ac-select-options-option--multi']
              )}
              key={singleOption[valueKey]}
              onClick={event => handleOptionClick(event, singleOption)}
              onKeyDown={event => handleFocusKeyDown(event, singleOption)}
              tabIndex={0}>
              {selectType === 'multi' && (
                <AcCheckbox
                  key={singleOption.id}
                  withLabel={false}
                  label={singleOption[labelKey]}
                  onClick={event => handleOptionClick(event, singleOption)}
                  checked={Array.from(selection).some(
                    selectedOption =>
                      selectedOption[valueKey] === singleOption[valueKey]
                  )}
                />
              )}
              <AcTypography
                size="sm"
                weight="light"
                element="span">
                {singleOption[labelKey] as string}
              </AcTypography>
            </span>
          </>
        )
      )
    }, [
      options,
      showOptions,
      renderOption,
      query,
      defaultValue,
      selectType,
      selection,
    ])

    const inputValue = useMemo(() => {
      if (query !== null) return query
      if (selectType === 'multi') {
        const values = Array.from(selection)
        return readOnly
          ? values.map(singleValue => singleValue[labelKey]).join(', ')
          : values.map(singleValue => (
              <AcButton
                color="neutral-700"
                padding="chip"
                onClick={e => handleOptionClick(e, singleValue)}
                label={singleValue[labelKey] as string}
                key={singleValue[labelKey]}>
                <AcIcon icon={ICONS.CLOSE} />
              </AcButton>
            ))
      } else {
        if (inputFormat === 'object' && defaultValue?.[labelKey] != null)
          return defaultValue[labelKey]
        if (inputFormat === 'value' && defaultValue != null) {
          const option = options.find(
            // using == instead of === because Corne :D
            singleOption => defaultValue == singleOption[valueKey]
            // ({ [valueKey]: _value }) => defaultValue == _value
          )
          return option?.[labelKey]
        }
      }
    }, [query, defaultValue, labelKey, options, selection, readOnly])

    //@TODO Render correct placeholder when withSearch. Now using value and a className
    const renderInput = useMemo(() => {
      return withSearch ? (
        <input
          ref={ref}
          placeholder={placeholder}
          className={styles['ac-select-input']}
          name={name}
          onClick={event => {
            event.stopPropagation()
            setShowOptions(true)
          }}
          onChange={e => setQuery(e.target.value)}
          id={id}
          value={inputValue ?? ''}
        />
      ) : (
        <AcTypography
          size="sm"
          element="span"
          weight="light"
          className={clsx(
            styles['ac-select-label'],
            selectType === 'multi' && styles['ac-select-label-multi']
          )}>
          {!inputValue ? (
            <span className={styles['ac-select-placeholder']}>
              {placeholder}
            </span>
          ) : (
            inputValue
          )}
        </AcTypography>
      )
    }, [withSearch, name, setQuery, id, inputValue, ref])

    const renderEndAdornment = useMemo(() => {
      const classNames = clsx([
        styles['ac-select-icon'],
        showOptions && styles['ac-select-icon--opened'],
      ])

      return (
        <AcIcon
          icon={ICONS.ANGLE_DOWN}
          className={classNames}
        />
      )
    }, [showOptions, readOnly])

    const renderReadOnly = useCallback(() => {
      return (
        <AcTypography
          weight="semibold"
          size="sm"
          element="span">
          {!inputValue ? (
            <span className={styles['ac-select-placeholder']}>
              {placeholder ?? '-'}
            </span>
          ) : (
            inputValue
          )}
        </AcTypography>
      )
    }, [inputValue])

    useEffect(() => {
      if (selectType === 'multi' && defaultValue?.length) {
        setSelection(defaultValue)
      }
    }, [selectType, defaultValue])

    useEffect(() => {
      if (selectType === 'multi') {
        onChange(selection)
      }
    }, [selection])

    return (
      <div
        ref={dropdownRef}
        onClick={() => {
          setShowOptions(!showOptions)
        }}
        className={clsx(
          styles['ac-select'],
          hint && styles['ac-select--has-hint'],
          readOnly && styles['ac-select--read-only'],
          className && className
        )}>
        <AcInputBase
          tabIndex={readOnly ? void 0 : 0}
          disabled={disabled}
          hint={!readOnly && hint}
          loading={loading}
          endAdornment={readOnly ? null : renderEndAdornment}
          error={error}
          withoutBorders={readOnly}
          onKeyDown={readOnly ? void 0 : handleFocusKeyDown}>
          {readOnly
            ? renderReadOnly()
            : renderOption
            ? renderOption(defaultValue)
            : renderInput}
        </AcInputBase>
        {!readOnly && showOptions && (
          <AcCard
            className={styles['ac-select-options-wrapper']}
            elevation="sm"
            padding={8}>
            {withEmpty && (
              <option
                className={clsx(
                  styles['ac-select-options-option'],
                  styles['ac-select-options-option--default']
                )}
                onClick={event =>
                  handleOptionClick(event, { [valueKey]: null })
                }
                onKeyDown={event =>
                  handleFocusKeyDown(event, { [valueKey]: null })
                }
                tabIndex={0}>
                <AcTypography
                  size="sm"
                  weight="light"
                  element="span">
                  {`(${LABELS.EMPTY})`}
                </AcTypography>
              </option>
            )}
            {renderOptions()}
          </AcCard>
        )}
      </div>
    )
  }
)
AcSelect.displayName = 'AcSelect'
export { AcSelect }
