import {
  ICustomerProfile,
  ISingleTag,
  ITemplateInfo,
  ITemplateProduct,
  ITemplateTodoCommentData,
  ITemplateTodoTaskData,
} from '@typings'
import { STORE_KEYS } from './keys.contants'

const API = '/api'
const APP = '/app'
const ARTICLES = '/articles'
const AUTH = '/auth'
const BASE = '/base'
const BROADCASTING = '/broadcasting'
const CATEGORIES = '/categories'
const CUSTOMER = '/customer'
const CLAIM = '/claim'
const CLEAR = '/clear'
const COPY = '/copy'
const DAYS = '/days'
const EMPLOYEES = '/employees'
const FRUIT = '/fruit'
const GROUPS = '/groups'
const INVOICE = '/invoice'
const LATEST = '/latest'
const LUNCHERS = '/lunchers'
const ORDER = '/order'
const PROFILE = '/profile'
const PRODUCTS = '/products'
const PRODUCT = '/product'
const SWITCH = '/switch'
const SEARCH = '/search'
const SELECTABLE_CATEGORIES = '/selectable-categories'
const SHUFFLE = '/shuffle'
const SITES = '/sites'
const SUPPLIERS = '/suppliers'
const TEMPLATES = '/templates'
const TAGS = '/tags'
const TASKS = '/tasks'
const TODOS = '/todos'
const TMP = '/tmp'
const MENU = '/menu'
const COMMENT = '/comment'
const REPLY = '/reply'
const FINISH = '/finish'
const VALIDATE = '/validate'

export const ENDPOINTS = {
  AUTH: {
    TEMP_TOKEN: () => `${API}${BASE}${AUTH}${TMP}` as const,
    SOCKET: () => `${process.env.REACT_APP_NEXTBASE_API}${BROADCASTING}${AUTH}`,
  },
  CUSTOMER: {
    SINGLE: (id: any) => `${API}${BASE}${CUSTOMER}/${id}${PROFILE}`,
    PROFILE_SINGLE: (id: ICustomerProfile['customer_id']) =>
      `${API}${BASE}${CUSTOMER}/${id}${PROFILE}${LATEST}`,
  },
  PRODUCTS: {
    LIST: () => `${API}${BASE}${PRODUCTS}`,
    SINGLE: (id: number) => `${API}${BASE}${PRODUCTS}/${id}`,
    ARCHIVE: (id: number) => `${API}${BASE}${PRODUCTS}/${id}/archive`,
    GROUP_LIST: () => `${API}${BASE}${PRODUCT}${GROUPS}`,
    CATEGORY_LIST: () => `${API}${BASE}${PRODUCT}${CATEGORIES}`,
    SELECTABLE_CATEGORY_LIST: () =>
      `${API}${BASE}${PRODUCT}${CATEGORIES}${SELECTABLE_CATEGORIES}`,
    SITE_LIST: () => `${API}${BASE}${PRODUCT}${SITES}`,
  },
  SUPPLIERS: {
    LIST: () => `${API}${BASE}${SUPPLIERS}`,
    SINGLE: (id: number) => `${API}${BASE}${SUPPLIERS}/${id}`,
    ARCHIVE: (id: number) => `${API}${BASE}${SUPPLIERS}/${id}/archive`,
  },
  INVOICES: {
    ARTICLE_LIST: () => `${API}${BASE}${INVOICE}${ARTICLES}`,
  },
  GLOBAL_SEARCH: {
    SEARCH: () => `${API}${BASE}${APP}${SEARCH}` as const,
  },
  TEMPLATES: {
    SINGLE: (id: number) => `${API}${BASE}${ORDER}${TEMPLATES}/${id}`,
    CATEGORY_OPTION_LIST: (id: number) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${id}${PRODUCTS}`,
    SHUFFLE: (id: number) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${id}${SHUFFLE}`,
    PRODUCT: (id: number, template_product_id: number) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${id}${PRODUCTS}/${template_product_id}`,
    ADD_PRODUCT: (id: number) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${id}${PRODUCTS}`,
    LUNCHERS: (id: number) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${id}${DAYS}${LUNCHERS}`,
    FINISH: (template_id: ITemplateInfo['id']) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${template_id}${FINISH}`,
    VALIDATE: (template_id: ITemplateInfo['id']) =>
      `${API}${BASE}${ORDER}${TEMPLATES}/${template_id}${VALIDATE}`,
    DAYS: {
      SWITCH: (id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${DAYS}${SWITCH}`,
      COPY: (id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${DAYS}${COPY}`,
      CLEAR: (id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${DAYS}${CLEAR}`,
    },
    CLAIM: (id: number) => `${API}${BASE}${ORDER}${TEMPLATES}/${id}${CLAIM}`,
    TODO: {
      COMPLETE: (id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${TODOS}`,
      HANDLE_MENU: (id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${MENU}`,
      HANDLE_TASK: (
        id: ITemplateInfo['id'],
        taskId: ITemplateTodoTaskData['id']
      ) => `${API}${BASE}${ORDER}${TEMPLATES}/${id}${TASKS}/${taskId}`,
      HANDLE_COMMENT_REPLY: (
        id: ITemplateInfo['id'],
        comment_id: ITemplateTodoCommentData['id']
      ) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${id}${COMMENT}/${comment_id}${REPLY}`,
    },
    TASK: {
      COMPLETE: (template_id: number, task_id: number) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${template_id}${TASKS}/${task_id}`,
    },
    FRUIT: {
      QUANTITY: (
        template_id: ITemplateInfo['id'],
        product_id: ITemplateProduct['product_id']
      ) =>
        `${API}${BASE}${ORDER}${TEMPLATES}/${template_id}${FRUIT}/${product_id}`,
    },
  },
  EMPLOYEES: {
    LIST: () => `${API}${BASE}${EMPLOYEES}`,
  },
  TAGS: {
    LIST: () => `${API}${BASE}${PRODUCT}${TAGS}`,
    SINGLE: (id: ISingleTag['id']) => `${API}${BASE}${PRODUCT}${TAGS}/${id}`,
  },
}

export const DATAGRID_ENDPOINTS: { [key: string]: string } = {
  [STORE_KEYS.PRODUCTS]: ENDPOINTS.PRODUCTS.LIST(),
  [STORE_KEYS.TAGS]: ENDPOINTS.TAGS.LIST(),
  [STORE_KEYS.SUPPLIERS]: ENDPOINTS.SUPPLIERS.LIST(),
} as const
