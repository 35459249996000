import { KEYS } from '@constants'
import {
  GlobalSearchTabs,
  ProductDetailTabs,
  SupplierDetailTabs,
  TemplateSidebarTabs,
} from '@tabs'
import { LABELS } from './labels.constants'

export const PRODUCT_DETAIL_TABS = [
  {
    id: KEYS.PRODUCT_INFORMATION,
    label: LABELS.PRODUCT_INFORMATION,
    component: ProductDetailTabs.ProductInformationTab,
    disabled: false,
  },
  {
    id: KEYS.PRODUCT_LOCATION,
    label: LABELS.LOCATION,
    component: ProductDetailTabs.ProductLocationTab,
    disabled: false,
  },
]
export const SUPPLIER_DETAIL_TABS = [
  {
    id: KEYS.SUPPLIER_INFORMATION,
    label: LABELS.SUPPLIER_INFORMATION,
    component: SupplierDetailTabs.SupplierInformationTab,
    disabled: false,
  },
  {
    id: KEYS.SUPPLIER_CONTACTS,
    label: LABELS.CONTACTS,
    component: SupplierDetailTabs.SupplierContactsTab,
    disabled: false,
  },
]

export const GLOBAL_SEARCH_TABS = [
  {
    id: KEYS.ALL,
    label: LABELS.ALL,
    component: GlobalSearchTabs.GlobalSearchAllTab,
    disabled: false,
  },
  {
    id: KEYS.WEEK_ORDER,
    label: LABELS.WEEK_ORDERS,
    component: GlobalSearchTabs.GlobalSearchWeekOrdersTab,
    disabled: false,
  },
  {
    id: KEYS.CUSTOMER,
    label: LABELS.CUSTOMERS,
    component: GlobalSearchTabs.GlobalSearchCustomersTab,
    disabled: false,
  },
  {
    id: KEYS.TEMPLATE,
    label: LABELS.TEMPLATES,
    component: GlobalSearchTabs.GlobalSearchTemplatesTab,
    disabled: false,
  },
]
