export const TEMPLATE_TODO_STATES = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  NEW: 'NEW',
  WARNING: 'WARNING',
} as const

export const TEMPLATE_TODO_TYPES = {
  OCCUPANCY: 'OCCUPANCY',
  MENU: 'MENU',
  COMMENT: 'COMMENT',
  TASKS: 'TASKS',
} as const

export const TEMPLATE_TAG_PREFERENCE_COLORS: Record<number, string> = {
  // NEGATIVE
  0: 'var(--ac-color-feedback-error)',
  // LIMITED
  1: 'var(--ac-color-primary-500)',
  // OKAY
  3: 'var(--ac-color-neutral-500)',
  // POSITIVE
  5: 'var(--ac-color-secondary-500)',
}
