import { AcForm, AcFormCard, AcFormInput } from '@components'
import { KEYS, LABELS, SITES } from '@constants'
import { useStore } from '@hooks/use-store'
import { IProduct } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

export type IProductLocationForm = {
  onError: () => undefined | void
}

export const ProductLocationForm = observer(
  ({ onError }: IProductLocationForm) => {
    const FormInput = AcFormInput<IProduct>

    const { products } = useStore()
    const { getValues, watch, setValue } = useFormContext<IProduct>()

    const locations = watch('locations')

    const getRackOptions = useCallback(
      (site_id: number, site_index: number) => {
        const { spaces } =
          products.joins?.sites?.find?.(({ id }) => id === site_id) || {}
        if (!spaces) return []

        const space_id = getValues(
          `${KEYS.LOCATIONS}.${site_index}.${KEYS.SPACE_ID}`
        )

        const space = spaces.find(({ id }) => id === space_id)
        const { rack_count } = space || {}

        // Generate AcSelect options that counts till rack_count
        if (rack_count)
          return Array.from({ length: rack_count }, (_, i) => ({
            label: i + 1,
            value: i + 1,
          }))
        else return []
      },
      [products.joins.sites, locations]
    )

    const generateFiftyShadesOfShelves = useCallback(
      () =>
        Array.from({ length: 50 }, (_, i) => ({
          label: i + 1,
          value: i + 1,
        })),
      []
    )

    /**
     * Render location cards.
     * Will hide cards until locations array has same size as SIZE.
     * See locations useEffect
     */
    const renderFormCards = () => {
      if (!locations?.length || locations?.length < SITES.length) return null

      const formCards = []
      for (const [index, location] of locations.entries()) {
        const { site_id } = location
        const { label } = SITES.find(({ id }) => site_id === id) || {}

        const { spaces = [] } =
          products.joins.sites.find(({ id }) => id === site_id) || {}

        formCards.push(
          <AcFormCard title={label || ''}>
            <FormInput
              name={`${KEYS.LOCATIONS}.${index}.${KEYS.SPACE_ID}`}
              label={LABELS.SPACE}
              type="select"
              labelKey="label"
              options={spaces}
              valueKey="id"
              inputFormat="value"
              outputFormat="value"
              withEmpty
            />
            <FormInput
              name={`${KEYS.LOCATIONS}.${index}.${KEYS.RACK}`}
              label={LABELS.RACK}
              type="select"
              labelKey="label"
              options={getRackOptions(site_id, index)}
              valueKey="value"
              inputFormat="value"
              outputFormat="value"
            />
            <FormInput
              name={`${KEYS.LOCATIONS}.${index}.${KEYS.SHELVE}`}
              label={LABELS.SHELVE}
              type="select"
              labelKey="label"
              options={generateFiftyShadesOfShelves()}
              valueKey="value"
              inputFormat="value"
              outputFormat="value"
            />
            <FormInput
              name={`${KEYS.LOCATIONS}.${index}.${KEYS.COLOR}`}
              label={LABELS.COLOR}
              type="select"
              labelKey="label"
              options={products.joins.colors}
              valueKey="value"
              inputFormat="value"
              outputFormat="value"
            />
          </AcFormCard>
        )
      }
      return formCards
    }

    /**
     * Check if response (or initial) `locations` is same size as SITES.
     * When SITE does not exist in locations, add {SITE_ID} to locations to
     * provide site with empty fields
     */
    useEffect(() => {
      if (!locations?.length || locations?.length < SITES.length) {
        for (const [index, site] of SITES.entries()) {
          const locationExists = locations?.some?.(
            ({ [KEYS.SITE_ID]: site_id }) => site_id === site.id
          )
          if (!locationExists) {
            const newIndex = isNaN(locations?.length) ? index : locations.length
            setValue(`${KEYS.LOCATIONS}.${newIndex}.${KEYS.SITE_ID}`, site.id)
          }
        }
      }
    }, [locations])

    return <AcForm onError={onError}>{renderFormCards()}</AcForm>
  }
)
