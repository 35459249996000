import { AcCheckbox, AcIcon, AcTooltip, AcTypography } from '@components'
import { DYNAMIC_LABELS, ICONS, LABELS } from '@constants'
import { ITemplateTodoTaskData } from '@typings'
import styles from './ac-template-todo-tasks-item.module.scss'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'

export const AcTemplateTodoTasksItem = ({
  is_done,
  interval,
  created_at,
  description,
  is_recurring,
  author,
  onClick,
  disabled = false,
}: Partial<ITemplateTodoTaskData> & {
  onClick: () => void
  disabled: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const getClasses = useMemo(
    () =>
      clsx(
        styles['ac-template-todo-tasks-item'],
        is_done && styles['ac-template-todo-tasks-item--is-checked'],
        loading && styles['ac-template-todo-tasks-item--loading']
      ),
    [is_done, loading]
  )

  const handleClick = useCallback(() => {
    if (disabled) return
    setLoading(true)
    onClick()
  }, [disabled])

  return (
    <div className={getClasses}>
      <AcCheckbox
        checked={is_done}
        onClick={handleClick}
        label={''}
      />
      <div>
        <AcTypography
          className={styles['ac-template-todo-tasks-item-description']}
          size="xs"
          element="span">
          {description}
        </AcTypography>
        <AcTypography
          className={styles['ac-template-todo-tasks-item-author']}
          size="xs"
          weight="light"
          element="span">
          {is_recurring && (
            <span className={styles['ac-template-todo-tasks-item-repeat']}>
              <AcTooltip
                label={
                  interval && interval === 1
                    ? LABELS.REPEATS_EVERY_WEEK
                    : LABELS.REPEATS_EVERY_OTHER_WEEK
                }
                visible>
                <AcIcon
                  className={styles['ac-template-todo-tasks-item-repeat-icon']}
                  icon={ICONS.REPEAT}
                  size={'xs'}
                />
                {`${LABELS.REPEATING}  - `}
              </AcTooltip>
            </span>
          )}
          {author && author.employee && (
            <AcTooltip
              label={DYNAMIC_LABELS.CREATED_AT(
                dayjs(created_at).format('DD-MM-YY - HH:MM:ss')
              )}>
              <span>{author.employee.name}</span>
            </AcTooltip>
          )}
        </AcTypography>
      </div>
    </div>
  )
}
