import { ICONS } from '@constants'
import clsx from 'clsx'
import { createRef, useEffect, useMemo } from 'react'
import { AcIcon, AcTypography } from '../index.core.component'
import styles from './ac-checkbox.module.scss'

interface ICheckboxProps {
  label: string
  name?: string
  withLabel?: boolean
  checked?: boolean
  indeterminant?: boolean
  onClick: (e: React.MouseEvent) => void
}

export const AcCheckbox = ({
  label,
  name,
  withLabel,
  checked,
  indeterminant = false,
  onClick,
}: ICheckboxProps) => {
  const inputRef = createRef<HTMLInputElement>()
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!onClick) return
    onClick(e)
  }

  const handleIndetermniant = (indeterminate: boolean) => {
    if (indeterminant && inputRef.current)
      inputRef.current.indeterminate = indeterminate
  }

  const getCheckboxClasses = useMemo(
    () =>
      clsx(
        styles['ac-checkbox'],
        checked && styles['ac-checkbox--checked'],
        indeterminant && styles['ac-checkbox--indeterminant']
      ),
    [checked, indeterminant]
  )

  useEffect(() => {
    handleIndetermniant(indeterminant)
  }, [indeterminant])

  return (
    <div
      id={name}
      className={getCheckboxClasses}
      role="checkbox"
      onClick={handleClick}>
      <input
        ref={inputRef}
        className={styles['ac-checkbox-input']}
        name={name}
        id={name}
        type="checkbox"
        checked={checked}
        aria-label={label}
      />
      <span className={styles['ac-checkbox-input-icon']}>
        <AcIcon icon={indeterminant ? ICONS.INDETERMINATE : ICONS.CHECK} />
      </span>
      {withLabel && (
        <AcTypography
          className={styles['ac-checkbox-label']}
          element="span"
          size="sm"
          weight="light">
          {label}
        </AcTypography>
      )}
    </div>
  )
}
