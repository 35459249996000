import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { IAuthEmployee } from '@typings'
import { makeAutoObservable } from 'mobx'

export class EmployeeStore {
  _store: Store
  loading: boolean

  all: IAuthEmployee[]

  constructor(store: Store) {
    makeAutoObservable(this)
    this._store = store
    this.loading = true
    this.all = []
  }

  getList = async () => {
    this._store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, true)
    try {
      const res = await this._store.api.employees.get_list()
      this._store.set(STORE_KEYS.EMPLOYEES, KEYS.ALL, res)
    } catch (e) {
      console.error(e)
    } finally {
      this._store.set(STORE_KEYS.EMPLOYEES, KEYS.LOADING, false)
    }
  }
}
