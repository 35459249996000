import {
  AcAlert,
  AcAppHeader,
  AcButton,
  AcFormProvider,
  AcIcon,
} from '@components'
import { GLOBAL_ERRORS, ICONS, KEYS, LABELS, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { CustomerProfileForm } from '@partials/forms/customer-profile-form/customer-profile-form'
import { ICustomerProfile } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { useParams } from 'react-router'
import { toast } from 'react-toastify'
export const AcCustomerProfile = observer((): JSX.Element => {
  const { customer, tags } = useStore()
  const { id } = useParams()
  const [formErrors, setFormErrors] = useState(new Set<string>())
  const [editMode, setEditMode] = useState(false)

  const handleSuccess = (updatedCustomer: ICustomerProfile) => {
    setFormErrors(new Set())
    setEditMode(false)
    form.reset(updatedCustomer)
    toast(LABELS.SAVED_PROFILE, {
      toastId: KEYS.SAVED_PROFILE,
      icon: <AcIcon icon={ICONS.CHECK} />,
    })
  }

  const handleErrors = (
    errors: Record<string, { message: string; type: string }>
  ) => {
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey => {
      form.setError(singleKey as string, {
        type: errors[singleKey].type ?? 'custom',
        message: errors[singleKey].message,
      })
    })
  }

  const handleUpdate = async (data: FieldValues) => {
    await customer
      .updateProfile(data as ICustomerProfile)
      .then(updatedProfile => handleSuccess(updatedProfile as ICustomerProfile))
      .catch(e => {
        handleErrors(e)
      })
  }

  const hasErrors = useMemo(() => !!formErrors.size, [formErrors])

  const getDefaultValues = async () => {
    const _id = parseInt(id ?? '')
    return await customer.getProfile(_id)
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const onFormSubmit = () => {
    form.handleSubmit(handleUpdate, e =>
      handleErrors(e as Record<string, { message: string; type: string }>)
    )()
  }

  useEffect(() => {
    if (id) customer.getProfile(parseInt(id))
  }, [id])

  useEffect(() => {
    tags.getList()
  }, [])

  return (
    <>
      <AcAppHeader
        showBackButton
        navigateBackTo={ROUTE_PATHS.CUSTOMER_DETAIL}
        title={`${LABELS.CUSTOMER_PROFILE} - ${customer.profile?.customer?.name}`}
        action={
          <AcButton
            onClick={editMode ? onFormSubmit : () => setEditMode(true)}
            label={editMode ? LABELS.SAVE : LABELS.MODIFY}
          />
        }
      />
      <AcAlert
        title={GLOBAL_ERRORS.FORM_STATE_INVALID}
        visible={hasErrors}
      />
      <AcFormProvider
        form={form}
        key={id}
        onSubmit={onFormSubmit}
        readOnly={!editMode}
        loading={customer.loading}>
        <CustomerProfileForm
          onError={() => setFormErrors(new Set('problem'))}
        />
      </AcFormProvider>
    </>
  )
})
