import { AcAvatar, AcButton, AcIcon, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'
import { useOnClickOutside } from '@hooks/use-click-outside'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { createRef, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import classes from './ac-template-header-claim.module.scss'

export const AcTemplateHeaderClaim = observer(() => {
  const { template } = useStore()
  const wrapRef = createRef<HTMLElement>()
  const [parentComponent, setParentComponent] =
    useState<HTMLButtonElement | null>(null)
  const [menuComponent, setMenuComponent] = useState<HTMLDivElement | null>(
    null
  )
  const { styles, attributes } = usePopper(parentComponent, menuComponent, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClaim = () => {
    template.handleClaimTemplate()
  }

  const handleClaimTakeover = () => {
    template.handleClaimTakeOver()
  }

  const handleClaimDelete = () => {
    template.handleClaimEnd()
  }

  useOnClickOutside(wrapRef, () => setMenuOpen(false))

  return (
    <span ref={wrapRef}>
      {template.current_claimer ? (
        <>
          <AcButton
            withToolTip={false}
            ref={setParentComponent}
            onClick={() => setMenuOpen(!menuOpen)}
            className={classes['ac-claim-menu-button']}
            color="secondary"
            label={''}
            padding="none">
            <AcAvatar
              src={template.current_claim_status.employee.avatar}
              text={template.current_claim_status.employee.name.slice(0, 2)}
              className={classes['ac-claim-menu-avatar-select']}
            />
            <AcIcon icon={menuOpen ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />
          </AcButton>
          {menuOpen && (
            <div
              style={{ ...styles.popper, zIndex: 99 }}
              ref={setMenuComponent}
              {...attributes.popper}>
              <div className={classes['ac-claim-menu']}>
                <div className={classes['ac-claim-menu-inner']}>
                  <span className={classes['ac-claim-menu-inner-left']}>
                    <AcAvatar
                      src={template.current_claim_status.employee.avatar}
                      text={template.current_claim_status.employee.name.slice(
                        0,
                        2
                      )}
                    />
                  </span>
                  <span className={classes['ac-claim-menu-inner-right']}>
                    <AcTypography
                      element="span"
                      size="sm"
                      weight="light">
                      {LABELS.CLAIMED_BY}
                    </AcTypography>
                    <AcTypography
                      element="span"
                      size="md"
                      weight="semibold">
                      {template.current_claim_status.employee.name}
                    </AcTypography>
                  </span>
                </div>
                <AcButton
                  icon={
                    template.claimed_by_user ? ICONS.X_CIRCLE : ICONS.SPIN_RIGHT
                  }
                  label={
                    template.claimed_by_user
                      ? LABELS.CLAIM_DELETE
                      : LABELS.CLAIM_TAKEOVER
                  }
                  color="secondary"
                  variant="text"
                  onClick={
                    template.claimed_by_user
                      ? () => handleClaimDelete()
                      : () => handleClaimTakeover()
                  }
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <AcButton
          label={LABELS.CLAIM}
          onClick={handleClaim}
        />
      )}
    </span>
  )
})
