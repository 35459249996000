import {
  AcButton,
  AcTable,
  AcTemplateTodoFinished,
  AcTypography,
} from '@components'
import {
  KEYS,
  LABELS,
  TEMPLATE_TODO_STATES,
  TEMPLATE_TODO_TYPES,
  WEEKDAYS,
} from '@constants'
import { useStore } from '@hooks/use-store'
import { ITemplateTodoOccupancyDataItem } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import styles from './ac-template-todo-occupancy.module.scss'

export const AcTemplateTodoOccupancy = observer(() => {
  const { template } = useStore()
  const rows = [
    { label: LABELS.LUNCHERS, key: KEYS.LUNCHERS },
    { label: LABELS.FRUIT_BOX, key: KEYS.BOXES },
    { label: LABELS.BANANA, key: KEYS.BANANAS },
  ]

  const renderHeader = useMemo(() => {
    const columns = Object.keys(WEEKDAYS).map((singleKey: string) =>
      WEEKDAYS[singleKey].slice(0, 2)
    )

    return (
      <tr>
        <th />
        {columns.map(singleColumn => (
          <td key={singleColumn}>
            <AcTypography
              size="xs"
              className={styles['ac-template-todo-occupancy-table-header']}
              element="span">
              {singleColumn}
            </AcTypography>
          </td>
        ))}
      </tr>
    )
  }, [template.current_todo_occupancy?.data])

  const renderRows = useMemo(() => {
    return rows.map(({ key, label }) => {
      const rowData = template.current_todo_occupancy?.data[
        key
      ] as ITemplateTodoOccupancyDataItem

      return (
        <tr key={key}>
          <td>
            <AcTypography size="xs">{label}</AcTypography>
          </td>
          {rowData &&
            Object.keys(rowData).map(singleDataItem => (
              <td key={singleDataItem}>
                <span
                  className={styles['ac-template-todo-occupancy-table-item']}>
                  {rowData[singleDataItem]}
                </span>
              </td>
            ))}
        </tr>
      )
    })
  }, [template.current_todo_occupancy])

  const handleTakeOver = () => {
    template.completeTodo('OCCUPANCY', true)
  }

  const handleFinishTask = () => {
    template.completeTodo(TEMPLATE_TODO_TYPES.OCCUPANCY, false)
  }

  return (
    <div>
      <AcTypography size="xs">
        {template.current_todo_occupancy?.data.description}
      </AcTypography>
      <div className={styles['ac-template-todo-occupancy-table-wrapper']}>
        <AcTable>
          {renderHeader}
          {renderRows}
        </AcTable>
      </div>
      {template.user_can_edit && (
        <div className={styles['ac-template-todo-occupancy-button-wrapper']}>
          {template.current_todo_occupancy?.state ===
          TEMPLATE_TODO_STATES.DONE ? (
            <AcTemplateTodoFinished />
          ) : (
            template.current_todo_occupancy?.state ===
              TEMPLATE_TODO_STATES.NEW && (
              <>
                <AcButton
                  variant="text"
                  label={LABELS.TAKE_OVER}
                  color="secondary"
                  onClick={handleTakeOver}
                />
                <AcButton
                  variant="text"
                  label={LABELS.FINISH_TASK}
                  onClick={handleFinishTask}
                />
              </>
            )
          )}
        </div>
      )}
    </div>
  )
})
