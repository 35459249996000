import { ICONS } from '@constants'
import clsx from 'clsx'
import { useMemo } from 'react'
import { AcIcon, AcLoader } from '../index.core.component'

import styles from './ac-switch.module.scss'

interface IAcSwitchProps {
  checked: boolean
  size?: 'sm' | 'md'
  loading?: boolean
  onClick: (newValue?: boolean) => void
}

export const AcSwitch = ({
  size = 'md',
  loading,
  checked,
  onClick,
}: IAcSwitchProps) => {
  const getSwitchClassNames = useMemo(
    () =>
      clsx(
        styles['ac-switch'],
        styles[`ac-switch--${size}`],
        checked && styles['ac-switch--checked'],
        loading && styles['ac-switch--is-loading']
      ),
    [size, checked, loading]
  )

  const getSwitchIcon = useMemo(
    () => (checked ? ICONS.CHECK : ICONS.CLOSE),
    [checked]
  )

  const handleCallBack = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!onClick || loading) return
    onClick(!checked)
  }

  return (
      <label
        className={getSwitchClassNames}
        onClick={e => handleCallBack(e)}>
        <input
          className={styles['ac-switch-checkbox']}
          type="checkbox"
          checked={checked}
        />
        <span className={styles['ac-switch-slider']}>
          <span className={styles['ac-switch-slider-button']}>
            {loading ? (
              <span className={styles['ac-switch-slider-button-loader']}>
                <AcLoader variant="secondary" />
              </span>
            ) : (
              <AcIcon
                icon={getSwitchIcon}
                className={styles['ac-switch-slider-button-icon']}
              />
            )}
          </span>
        </span>
      </label>
  )
}
