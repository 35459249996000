import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IAuthEmployee,
  ITemplateCategoryOption,
  ITemplateInfo,
  ITemplateProduct,
  ITemplateProductQuery,
  ITemplateResponse,
  ITemplateTodoCommentData,
  ITemplateTodoTaskData,
  ITemplateValidate,
} from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class TemplateApi extends AxiosBaseApi {
  /* DEFAULT CRUD */

  bibi_shuffle = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.SHUFFLE(id), data).then(
      response => response.data.data
    )

  handle_lunchers = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.LUNCHERS(id), data).then(
      response => response.data.data
    )

  copy_day = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.DAYS.COPY(id), data).then(
      res => res.data.data
    )

  switch_day = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.DAYS.SWITCH(id), data).then(
      res => res.data.data
    )

  clear_day = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.DAYS.CLEAR(id), data).then(
      res => res.data.data
    )

  add_product = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('post', ENDPOINTS.TEMPLATES.ADD_PRODUCT(id), data).then(
      res => res.data.data
    )

  handle_fruit_quantity = (
    id: ITemplateInfo['id'],
    product_id: ITemplateProduct['product_id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'put',
      ENDPOINTS.TEMPLATES.FRUIT.QUANTITY(id, product_id),
      data
    ).then(res => res.data.data)

  patch_product = (
    id: ITemplateInfo['id'],
    template_product_id: ITemplateProduct['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'patch',
      ENDPOINTS.TEMPLATES.PRODUCT(id, template_product_id),
      data
    ).then(res => res.data.data)

  patch_template = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request('patch', ENDPOINTS.TEMPLATES.SINGLE(id), data).then(
      response => response.data.data
    )

  get_single = (id: ITemplateInfo['id'], data: Partial<AxiosRequestConfig>) =>
    this.handle_request('get', ENDPOINTS.TEMPLATES.SINGLE(id), data).then(
      response => response.data.data
    )

  handle_validate = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request<ITemplateValidate>(
      'post',
      ENDPOINTS.TEMPLATES.VALIDATE(id),
      data
    ).then(response => response.data.data)

  handle_template_finish = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request<{ id: ID; name: string }>(
      'post',
      ENDPOINTS.TEMPLATES.FINISH(id),
      data
    ).then(response => response.data.data)

  handle_task = (
    id: ITemplateInfo['id'],
    taskId: ITemplateTodoTaskData['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'patch',
      ENDPOINTS.TEMPLATES.TODO.HANDLE_TASK(id, taskId),
      data
    ).then(response => response.data.data)

  complete_todo = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'post',
      ENDPOINTS.TEMPLATES.TODO.COMPLETE(id),
      data
    ).then(response => response.data.data)

  handle_menu_add = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'post',
      ENDPOINTS.TEMPLATES.TODO.HANDLE_MENU(id),
      data
    ).then(response => response.data.data)

  handle_menu_update = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'put',
      ENDPOINTS.TEMPLATES.TODO.HANDLE_MENU(id),
      data
    ).then(response => response.data.data)

  handle_menu_delete = (
    id: ITemplateInfo['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.api
      .delete<AxiosResponse<{ data: ITemplateResponse }>>(
        ENDPOINTS.TEMPLATES.TODO.HANDLE_MENU(id),
        data
      )
      .then(response => response.data.data)

  handle_comment_reply = (
    template_id: ITemplateInfo['id'],
    comment_id: ITemplateTodoCommentData['id'],
    data: Partial<AxiosRequestConfig>
  ) =>
    this.handle_request(
      'post',
      ENDPOINTS.TEMPLATES.TODO.HANDLE_COMMENT_REPLY(template_id, comment_id),
      data
    ).then(response => response.data.data)

  /* ACTIONS */

  handle_request = <T = ITemplateResponse>(
    method: 'get' | 'patch' | 'post' | 'put',
    endpoint: string,
    requestData: Partial<AxiosRequestConfig>
  ) => {
    //Request format is different for POST and PATCH
    if (method !== 'get') {
      return this.api[method]<AxiosResponse<{ data: T }>>(
        endpoint,
        { ...requestData.data }, //DATA
        { ['params']: requestData.params } //PARAMS as seperate object in config
      ).then(response => response)
    } else {
      return this.api[method]<AxiosResponse<{ data: T }>>(endpoint, {
        ...requestData,
      }).then(response => response)
    }
  }

  claim_start = (
    template_id: ITemplateInfo['id'],
    employee_id: IAuthEmployee['id']
  ) =>
    this.api
      .post<AxiosResponse<{ data: any }>>(
        ENDPOINTS.TEMPLATES.CLAIM(template_id),
        { employee_id }
      )
      .then(response => response.data.data)

  claim_update = (
    template_id: ITemplateInfo['id'],
    employee_id: IAuthEmployee['id']
  ) =>
    this.api
      .put<AxiosResponse<{ data: any }>>(
        ENDPOINTS.TEMPLATES.CLAIM(template_id),
        { employee_id }
      )
      .then(response => response.data.data)

  claim_delete = (template_id: ITemplateInfo['id']) =>
    this.api
      .delete<AxiosResponse<{ data: any }>>(
        ENDPOINTS.TEMPLATES.CLAIM(template_id)
      )
      .then(response => response.data.data)

  /* JOINS */

  get_category_option = (template_id: ID, params: ITemplateProductQuery) =>
    this.api
      .get<AxiosResponse<{ data: ITemplateCategoryOption[] }>>(
        ENDPOINTS.TEMPLATES.CATEGORY_OPTION_LIST(template_id),
        { params }
      )
      .then(response => response.data.data)
}
