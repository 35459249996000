import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import BbLogoMascotVectorInverted from '@assets/images/bb-logo-no-text.svg'
import BbLogoVector from '@assets/images/bb-logo.svg'

import {
  AcAvatar,
  AcButton,
  AcGlobalSearch,
  AcIcon,
  AcTextInput,
  AcTooltip,
  AcTypography,
} from '@components'
import { ICONS, LABELS, MENU, ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'

import styles from './ac-app-menu.module.scss'

export const AcAppMenu = observer(() => {
  const { pathname } = useLocation()
  const { ui, auth } = useStore()

  const getMenuListStyle = useMemo<React.CSSProperties>(() => {
    for (const [index, MENU_ITEM] of MENU.entries()) {
      const match = window.RegExp(`^\\${MENU_ITEM.path}`).test(pathname)
      if (match)
        return {
          '--ac-app-menu-list-selector--offset': index,
        } as React.CSSProperties
    }
    return {}
  }, [pathname])

  const openGlobalSearchModal = (
    event: React.FocusEvent<HTMLInputElement> | KeyboardEvent
  ) => {
    const target = event.target as HTMLInputElement
    target?.blur()
    ui.showModal(AcGlobalSearch, { width: 'xl', closeable: true }).catch(
      () => void 0
    )
  }

  const renderMenuItems = useMemo(() => {
    const items = []
    for (const MENU_ITEM of MENU) {
      items.push(
        <AcTooltip
          visible={ui.menu.dense}
          label={MENU_ITEM.label}>
          <li className={styles['ac-app-menu-list-item']}>
            <NavLink to={MENU_ITEM.path}>
              <AcIcon
                icon={MENU_ITEM.icon}
                className={styles['ac-app-menu-list-item-icon']}
              />
              <span className={styles['ac-app-menu-list-item-text']}>
                {MENU_ITEM.label}
              </span>
            </NavLink>
          </li>
        </AcTooltip>
      )
    }

    return items
  }, [ui.menu.dense])

  const handleLogout = () => {
    //Only remove token from storage, then redirect
    auth.clearToken()
    window.location.href = ROUTE_PATHS.LOGOUT
  }

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.code === 'Slash') {
        event.preventDefault()
        openGlobalSearchModal(event)
      }
    }
    window.addEventListener('keydown', keydownListener)
    return () => window.removeEventListener('keydown', keydownListener)
  }, [])

  return (
    <nav
      className={clsx(
        styles['ac-app-menu'],
        ui.menu.dense && styles['ac-app-menu--dense']
      )}>
      <div className={styles['ac-app-menu-logo']}>
        <AcButton
          className={styles['ac-app-menu-densinator']}
          variant="icon"
          onClick={() => ui.toggleMenuDense()}
          label={ui.menu.dense ? LABELS.EXPAND : LABELS.COLLAPSE}
          icon={ui.menu.dense ? ICONS.ANGLE_RIGHT : ICONS.ANGLE_LEFT}
          padding="xs"
          withToolTip={false}
        />
        <img
          src={ui.menu.dense ? BbLogoMascotVectorInverted : BbLogoVector}
          alt="Buurtboer logo"
        />
      </div>
      <AcTextInput
        name="app-global-search"
        id="app-global-search"
        className={styles['ac-app-menu-search']}
        type="text"
        placeholder={LABELS.SEARCH}
        startAdornment={<AcIcon icon={ICONS.SEARCH} />}
        onFocus={openGlobalSearchModal}
        padding={10}
        tabIndex={-1}
      />
      <ul
        style={getMenuListStyle}
        className={styles['ac-app-menu-list']}>
        {renderMenuItems}
      </ul>
      <div className={styles['ac-app-menu-profile']}>
        <AcAvatar
          src={auth.current_employee?.avatar || void 0}
          className={styles['ac-app-menu-profile-img']}
          alt={LABELS.PROFILE_AVATAR}
          text={auth.current_employee?.name?.substring(0, 2)}
        />
        <AcTypography
          className={styles['ac-app-menu-profile-name']}
          size="xs">
          {auth.current_employee?.name}
        </AcTypography>
        <AcButton
          className={styles['ac-app-menu-profile-logout']}
          variant="icon"
          onClick={handleLogout}
          label={LABELS.LOGOUT}
          icon={ICONS.SIDE_MENU}
          padding="md"
        />
      </div>
    </nav>
  )
})
