import { ReactComponent as AllergyIcon } from '@assets/icons/Allergy.svg'
import { ReactComponent as AngleDownIcon } from '@assets/icons/AngleDown.svg'
import { ReactComponent as AngleLeftIcon } from '@assets/icons/AngleLeft.svg'
import { ReactComponent as AngleRightIcon } from '@assets/icons/AngleRight.svg'
import { ReactComponent as AngleUpIcon } from '@assets/icons/AngleUp.svg'
import { ReactComponent as BibiIcon } from '@assets/icons/Bibi.svg'
import { ReactComponent as BibiAvatarIcon } from '@assets/icons/BibiAvatar.svg'
import { ReactComponent as BoldIcon } from '@assets/icons/Bold.svg'
import { ReactComponent as BookIcon } from '@assets/icons/Book.svg'
import { ReactComponent as BookAltIcon } from '@assets/icons/BookAlt.svg'
import { ReactComponent as BuildingsIcon } from '@assets/icons/Buildings.svg'
import { ReactComponent as CalendarIcon } from '@assets/icons/Calendar.svg'
import { ReactComponent as CalendarIncomingIcon } from '@assets/icons/CalendarIncoming.svg'
import { ReactComponent as ChartIcon } from '@assets/icons/Chart.svg'
import { ReactComponent as CheckIcon } from '@assets/icons/Check.svg'
import { ReactComponent as ChecklistIcon } from '@assets/icons/Checklist.svg'
import { ReactComponent as ClipboardList } from '@assets/icons/ClipboardList.svg'
import { ReactComponent as CloseIcon } from '@assets/icons/Close.svg'
import { ReactComponent as ContactsIcon } from '@assets/icons/Contacts.svg'
import { ReactComponent as CopyIcon } from '@assets/icons/Copy.svg'
import { ReactComponent as CulteryIcon } from '@assets/icons/Cultery.svg'
import { ReactComponent as DashboardIcon } from '@assets/icons/Dashboard.svg'
import { ReactComponent as DateAltIcon } from '@assets/icons/Date-1.svg'
import { ReactComponent as DateIcon } from '@assets/icons/Date.svg'
import { ReactComponent as DeleteIcon } from '@assets/icons/Delete.svg'
import { ReactComponent as EditIcon } from '@assets/icons/Edit.svg'
import { ReactComponent as EmailIcon } from '@assets/icons/Email.svg'
import { ReactComponent as ErrorIcon } from '@assets/icons/Error.svg'
import { ReactComponent as EuroIcon } from '@assets/icons/Euro.svg'
import { ReactComponent as ExportIcon } from '@assets/icons/Export.svg'
import { ReactComponent as ExternalIcon } from '@assets/icons/External.svg'
import { ReactComponent as EyesIcon } from '@assets/icons/Eyes.svg'
import { ReactComponent as FilterIcon } from '@assets/icons/Filter.svg'
import { ReactComponent as GoogleIcon } from '@assets/icons/Google.svg'
import { ReactComponent as HeartIcon } from '@assets/icons/Heart.svg'
import { ReactComponent as HelpIcon } from '@assets/icons/Help.svg'
import { ReactComponent as ImagePlaceholderIcon } from '@assets/icons/ImagePlaceholder.svg'
import { ReactComponent as InboxIcon } from '@assets/icons/Inbox.svg'
import { ReactComponent as IndeterminateIcon } from '@assets/icons/Indeterminate.svg'
import { ReactComponent as InfoIcon } from '@assets/icons/Info.svg'
import { ReactComponent as InvoiceIcon } from '@assets/icons/Invoice.svg'
import { ReactComponent as ItalicIcon } from '@assets/icons/Italic.svg'
import { ReactComponent as LabelsIcon } from '@assets/icons/Labels.svg'
import { ReactComponent as LinkIcon } from '@assets/icons/Link.svg'
import { ReactComponent as ListIcon } from '@assets/icons/List.svg'
import { ReactComponent as ListNrIcon } from '@assets/icons/ListNr.svg'
import { ReactComponent as LockIcon } from '@assets/icons/Lock.svg'
import { ReactComponent as MailIcon } from '@assets/icons/Mail.svg'
import { ReactComponent as MenuIcon } from '@assets/icons/Menu.svg'
import { ReactComponent as MicrowaveIcon } from '@assets/icons/Microwave.svg'
import { ReactComponent as MoreIcon } from '@assets/icons/More.svg'
import { ReactComponent as NoChatIcon } from '@assets/icons/NoChat.svg'
import { ReactComponent as NotificationIcon } from '@assets/icons/Notification.svg'
import { ReactComponent as PhoneIcon } from '@assets/icons/Phone.svg'
import { ReactComponent as PiggyIcon } from '@assets/icons/Piggy.svg'
import { ReactComponent as PlusIcon } from '@assets/icons/Plus.svg'
import { ReactComponent as PreviousIcon } from '@assets/icons/Previous.svg'
import { ReactComponent as QuestionIcon } from '@assets/icons/Question.svg'
import { ReactComponent as RangeIcon } from '@assets/icons/Range.svg'
import { ReactComponent as RepeatIcon } from '@assets/icons/Repeat.svg'
import { ReactComponent as RotateLeftIcon } from '@assets/icons/RotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '@assets/icons/RotateRight.svg'
import { ReactComponent as SearchIcon } from '@assets/icons/Search.svg'
import { ReactComponent as SettingsIcon } from '@assets/icons/Settings.svg'
import { ReactComponent as SettingsMagicIcon } from '@assets/icons/SettingsMagic.svg'
import { ReactComponent as SidebarLeftIcon } from '@assets/icons/SideBarLeft.svg'
import { ReactComponent as SideMenuIcon } from '@assets/icons/SideMenu.svg'
import { ReactComponent as SpinRightIcon } from '@assets/icons/SpinRight.svg'
import { ReactComponent as StackIcon } from '@assets/icons/Stack.svg'
import { ReactComponent as StoreIcon } from '@assets/icons/Store.svg'
import { ReactComponent as SupportIcon } from '@assets/icons/Support.svg'
import { ReactComponent as TicketIcon } from '@assets/icons/Ticket.svg'
import { ReactComponent as TruckIcon } from '@assets/icons/Truck.svg'
import { ReactComponent as UserIcon } from '@assets/icons/User.svg'
import { ReactComponent as UserAltIcon } from '@assets/icons/Users-v2.svg'
import { ReactComponent as UsersIcon } from '@assets/icons/Users.svg'
import { ReactComponent as UsersAltIcon } from '@assets/icons/UsersAlt.svg'
import { ReactComponent as VCircleIcon } from '@assets/icons/VCircle.svg'
import { ReactComponent as VCircleFilledIcon } from '@assets/icons/VCircleFilled.svg'
import { ReactComponent as WarningIcon } from '@assets/icons/Warning.svg'
import { ReactComponent as WarningCircleFilledIcon } from '@assets/icons/WarningCircleFilled.svg'
import { ReactComponent as XCircleIcon } from '@assets/icons/XCircle.svg'
import { ReactComponent as XCircleFilledIcon } from '@assets/icons/XCircleFilled.svg'

/* ---- ICONS ---- */

/* Whenever you want to use an Icon, we use the <AcIcon /> component. 
We can pass an Icon prop to it. The prop should be ICONS.<key_of_icon>. 
Within the AcIcon component we map the icon string to the correct
icon from ICON_ELEMENTS. */

export const ICON_ELEMENTS = {
  ALLERGY: AllergyIcon,
  ANGLE_DOWN: AngleDownIcon,
  ANGLE_LEFT: AngleLeftIcon,
  ANGLE_RIGHT: AngleRightIcon,
  ANGLE_UP: AngleUpIcon,
  BIBI: BibiIcon,
  BIBI_AVATAR: BibiAvatarIcon,
  BOLD: BoldIcon,
  BOOK: BookIcon,
  BOOK_ALT: BookAltIcon,
  BUILDINGS: BuildingsIcon,
  CALENDER: CalendarIcon,
  CALENDAR_INCOMING: CalendarIncomingIcon,
  CHART: ChartIcon,
  CHECK: CheckIcon,
  CHECKLIST: ChecklistIcon,
  COPY: CopyIcon,
  CLIPBOARD_LIST: ClipboardList,
  CLOSE: CloseIcon,
  CONTACTS: ContactsIcon,
  CULTERY: CulteryIcon,
  DASHBOARD: DashboardIcon,
  DATE_ALT: DateAltIcon,
  DATE: DateIcon,
  DELETE: DeleteIcon,
  EDIT: EditIcon,
  EMAIL: EmailIcon,
  ERROR: ErrorIcon,
  EURO: EuroIcon,
  EXPORT: ExportIcon,
  EYES: EyesIcon,
  EXTERNAL: ExternalIcon,
  FILTER: FilterIcon,
  GOOGLE: GoogleIcon,
  HEART: HeartIcon,
  HELP: HelpIcon,
  IMAGE: ImagePlaceholderIcon,
  INBOX: InboxIcon,
  INDETERMINATE: IndeterminateIcon,
  INFO: InfoIcon,
  INVOICE: InvoiceIcon,
  ITALIC: ItalicIcon,
  LABELS: LabelsIcon,
  LINK: LinkIcon,
  LIST_NR: ListNrIcon,
  LIST: ListIcon,
  LOCK: LockIcon,
  MICROWAVE: MicrowaveIcon,
  MAIL: MailIcon,
  MENU: MenuIcon,
  MORE: MoreIcon,
  NO_CHAT: NoChatIcon,
  NOTIFICATION: NotificationIcon,
  PIGGY: PiggyIcon,
  PHONE: PhoneIcon,
  PLUS: PlusIcon,
  PREVIOUS: PreviousIcon,
  QUESTION: QuestionIcon,
  RANGE: RangeIcon,
  REPEAT: RepeatIcon,
  ROTATE_LEFT: RotateLeftIcon,
  ROTATE_RIGHT: RotateRightIcon,
  SEARCH: SearchIcon,
  SETTINGS: SettingsIcon,
  SETTINGS_MAGIC: SettingsMagicIcon,
  SIDE_MENU: SideMenuIcon,
  SIDEBAR_LEFT: SidebarLeftIcon,
  SPIN_RIGHT: SpinRightIcon,
  STACK: StackIcon,
  STORE: StoreIcon,
  SUPPORT: SupportIcon,
  TICKET: TicketIcon,
  TRUCK: TruckIcon,
  USER: UserIcon,
  USER_ALT: UserAltIcon,
  USERS: UsersIcon,
  USERS_ALT: UsersAltIcon,
  X_CIRCLE: XCircleIcon,
  X_CIRCLE_FILLED: XCircleFilledIcon,
  V_CIRCLE: VCircleIcon,
  V_CIRCLE_FILLED: VCircleFilledIcon,
  WARNING: WarningIcon,
  WARNING_CIRCLE_FILLED: WarningCircleFilledIcon,
} as const

export const ICONS = {
  ALLERGY: 'ALLERGY',
  ANGLE_DOWN: 'ANGLE_DOWN',
  ANGLE_LEFT: 'ANGLE_LEFT',
  ANGLE_RIGHT: 'ANGLE_RIGHT',
  ANGLE_UP: 'ANGLE_UP',
  BIBI: 'BIBI',
  BIBI_AVATAR: 'BIBI_AVATAR',
  BOLD: 'BOLD',
  BOOK: 'BOOK',
  BOOK_ALT: 'BOOK_ALT',
  BUILDINGS: 'BUILDINGS',
  CALENDER: 'CALENDER',
  CALENDAR_INCOMING: 'CALENDAR_INCOMING',
  CHART: 'CHART',
  CHECK: 'CHECK',
  CHECKLIST: 'CHECKLIST',
  CLOSE: 'CLOSE',
  CONTACTS: 'CONTACTS',
  COPY: 'COPY',
  CULTERY: 'CULTERY',
  DASHBOARD: 'DASHBOARD',
  DATE_ALT: 'DATE_ALT',
  DATE: 'DATE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  EMAIL: 'EMAIL',
  ERROR: 'ERROR',
  EURO: 'EURO',
  EXPORT: 'EXPORT',
  EYES: 'EYES',
  EXTERNAL: 'EXTERNAL',
  FILTER: 'FILTER',
  GOOGLE: 'GOOGLE',
  HEART: 'HEART',
  HELP: 'HELP',
  IMAGE: 'IMAGE',
  INBOX: 'INBOX',
  INDETERMINATE: 'INDETERMINATE',
  INFO: 'INFO',
  INVOICE: 'INVOICE',
  ITALIC: 'ITALIC',
  LABELS: 'LABELS',
  LINK: 'LINK',
  LIST_NR: 'LIST_NR',
  LIST: 'LIST',
  LOCK: 'LOCK',
  MAIL: 'MAIL',
  MENU: 'MENU',
  MICROWAVE: 'MICROWAVE',
  MORE: 'MORE',
  NO_CHAT: 'NO_CHAT',
  NOTIFICATION: 'NOTIFICATION',
  PIGGY: 'PIGGY',
  PHONE: 'PHONE',
  PLUS: 'PLUS',
  PREVIOUS: 'PREVIOUS',
  QUESTION: 'QUESTION',
  RANGE: 'RANGE',
  REPEAT: 'REPEAT',
  ROTATE_LEFT: 'ROTATE_LEFT',
  ROTATE_RIGHT: 'ROTATE_RIGHT',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  SETTINGS_MAGIC: 'SETTINGS_MAGIC',
  SIDE_MENU: 'SIDE_MENU',
  SIDEBAR_LEFT: 'SIDEBAR_LEFT',
  SPIN_RIGHT: 'SPIN_RIGHT',
  STACK: 'STACK',
  STORE: 'STORE',
  SUPPORT: 'SUPPORT',
  TICKET: 'TICKET',
  TRUCK: 'TRUCK',
  USER: 'USER',
  USER_ALT: 'USER_ALT',
  USERS: 'USERS',
  USERS_ALT: 'USERS_ALT',
  V_CIRCLE: 'V_CIRCLE',
  X_CIRCLE: 'X_CIRCLE',
  X_CIRCLE_FILLED: 'X_CIRCLE_FILLED',
  V_CIRCLE_FILLED: 'V_CIRCLE_FILLED',
  WARNING: 'WARNING',
  WARNING_CIRCLE_FILLED: 'WARNING_CIRCLE_FILLED',
} as const
