import { AcButton, AcDialog, AcTypography } from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import styles from './template-claim-overtake.module.scss'

export const TemplateClaimOvertakeModal = observer(() => {
  const { ui, template } = useStore()

  const handleToChecklist = () => {
    // console.log('test')
  }

  const handleViewTemplate = () => {
    if (ui.modal && ui.modal.reject) {
      ui.modal.reject()
    }
  }

  return (
    <AcDialog
      title={DYNAMIC_LABELS.TEMPLATE_TAKEOVER(
        template.current_claimer.name,
        template.current_info.name
      )}
      className={styles['ac-template-claim-overtake']}>
      <div className={styles['ac-template-claim-overtake-content']}>
        <AcTypography
          className={styles['ac-template-claim-overtake-content-label']}
          size="xs"
          color="neutral-700"
          element="span"
          weight="light">
          {LABELS.TEMPLATE_TAKEOVER_TEXT}
        </AcTypography>
        <div className={styles['ac-template-claim-overtake-content-inner']}>
          <AcTypography
            size="sm"
            weight="semibold"
            element="span">
            {LABELS.CLAIMED_BY}
          </AcTypography>
          <div className={styles['ac-template-claim-overtake-claimer']}>
            <img
              src={template.current_claimer.avatar}
              className={styles['ac-template-claim-overtake-claimer-image']}
            />
            <span
              className={styles['ac-template-claim-overtake-claimer-inner']}>
              <AcTypography
                size="sm"
                weight="semibold"
                element="span">
                {template.current_claimer.name}
              </AcTypography>
              <AcTypography
                size="xs"
                weight="light"
                element="span">
                {dayjs(template.current_claimer.updated_at).format(
                  'DD-MM-YY HH:mm'
                )}
              </AcTypography>
            </span>
          </div>
        </div>
      </div>
      <div className={styles['ac-template-claim-overtake-actions']}>
        <AcButton
          color="secondary"
          label={LABELS.TO_CHECKLIST}
          onClick={handleToChecklist}
        />
        <AcButton
          color="primary"
          label={LABELS.VIEW_TEMPLATE}
          onClick={handleViewTemplate}
        />
      </div>
    </AcDialog>
  )
})
